// Animation
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-300px * 10))
    }
}


// Styling
.slider {
    margin: 0;
    padding-bottom: 10px;
    overflow: hidden !important;
    position: relative;
    width: 100%;

    &::before,
    &::after {
        content: '';
        position: absolute;
        z-index: 2;
    }

    &::after {
        right: 0;
        top: 0;
        transform: rotateZ(180deg);
    }

    &::before {
        left: 0;
        top: 0;
    }

    .slide-track {
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: normal;
        animation-fill-mode: none;
        animation-play-state: running;
        animation-name: scroll;
        display: flex;
        width: calc(300px * 20);
        gap: 10px;
    }

    .slide {
        width: 300px;

        @include for-phone {
            width: 200px;
            max-height: 112.56px;
        }

        img {
            border-radius: 10px;
            width: 300px;

            @include for-phone {
                width: 200px;
            }
        }

        :hover {
            transition: opacity 300ms;
            opacity: 1 !important;
        }


        .cover {
            @include for-phone {
                display: none;
            }

            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            width: 300px;
            height: 169px;
            background: rgba(0, 0, 0, 0.4);
            opacity: 0;
            border-radius: 10px;
            z-index: 1;

            .cta {
                @include button;
                color: $deep-black;
                background-color: $pure-white;
                border: 0.0625rem solid $pure-white;
                cursor: pointer;
            }
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {

    #slide {
        display: none;
    }

    .faq {
        border-bottom: none;

        .float-container {

            span {
                display: none;
            }

            h4:after {
                content: "FAQ's"
            }

            .float-child {
                width: 100%;
            }
        }
    }

    #hero {
        padding-top: 10vh;
        padding-bottom: 10vh;

        h3 {
            text-align: left;
            font-size: 2.5em;
        }

        p {
            width: 75%;
            margin-top: 20px;
            text-align: left;
            font-size: 0.875rem;
        }

        .disclaimer {
            text-align: left;
        }
    }

    .holder {
        &.horizontal {
            height: 350px;
        }
    }

}
@media only screen and (min-device-width: 280px) and (max-device-width: 600px) and (-webkit-min-device-pixel-ratio: 2) {

    #hero {
        margin: 50px 0 50px 0;

        h3 {
            text-align: left;
            font-size: 2.2em;
            margin-top: 0;
        }

        p {
            margin-top: 20px;
            text-align: left;
            font-size: 12px;
        }

        a {
            padding: 0.6rem 2.5em 0.6rem;
        }
    }

    .arrow-buttons {
        display: none;
    }

    .faq {
        border-bottom: none;

        .float-container {
            flex-direction: column;

            span {
                display: none;
            }

            h4:after {
                content: "FAQ's"
            }

            .float-child {
                padding: 0;
                width: 100%;
            }
        }

        margin-top: 2rem;
    }

    #slide {
        display: none;
    }

    .overlay {
        a {
            font-size: 1.25rem;
        }

        .close-button {
            top: 0rem;
            right: 1.5625rem;
        }
    }

    .main-background {
        .video {
            opacity: 1;
        }
    }


    .homepage-card {
        .special-padding {
            padding-left: 20px;
        }
    }

    .content-overview {
        width: 100%;
    }

    // .network {
    //     display: none;
    // }

}
#root,
html,
body {
    background: radial-gradient(circle farthest-side at 0% 100%, $dark-background 0%, #2f2f2f00 100%),
        radial-gradient(circle farthest-side at 100% 100%, $dark-background 0%, $dark-background 100%),
        radial-gradient(circle farthest-side at 100% 0%, $dark-background 0%, #4c4c4c00 100%),
        radial-gradient(circle farthest-side at 0% 0%, #3a3a3aff 0%, #3a3a3a00 100%), black;
    font-family: 'Helvetica Neue', 'Inter', sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    background-color: $dark-background;
}

// #root {
//     &::after {
//         content: '';
//         display: block;
//         height: 10px;
//         width: 100%;
//         background-color: $bright-green;
//     }
// }

::-webkit-scrollbar {
    display: none;
}

#logo {
    height: 35px;
}

.pad-4 {
    padding: 0 4rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 5px;
    margin-top: 30px;
    font-weight: 500;
    line-height: 1.2;
}

.content {
    h3 {
        font-weight: 200 !important;
    }

    margin: 4rem 0;
}

// .material-symbols-outlined {
//     color: $pure-white;
// }

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 200,
        'GRAD' 0,
        'opsz' 40;
}

.main {
    position: relative;
    height: 100%;
    margin-bottom: 3rem;

    &.homepage {
        margin-bottom: 0rem;
    }

    @include for-phone {
        margin-bottom: 1rem;
    }
}

#hero {
    padding: 0 0 0 4rem;

    @include for-phone {
        padding: 0 1.5rem 0 1.5rem;
    }

    h3>span {
        color: $bright-green;
    }

    .custom-button {
        margin-top: 20px;
    }

    .material-symbols-outlined {
        vertical-align: middle;
        font-size: 20px;
        margin-bottom: 3px;
        font-weight: 700 !important;
    }

    h3 {
        font-size: 60px;
        margin: 0;
        line-height: 1.05;
        font-weight: 700;
        letter-spacing: -0.015em;
        color: $pure-white;
        opacity: 0.999;
        text-transform: uppercase;

        @include for-desktop {
            max-width: 600px;
        }
    }

    p {
        margin-top: 20px;
        font-size: 13px;
        font-weight: 200;
        line-height: 1.45;
        text-align: left;
        color: $pure-white;
        margin-bottom: 30px;

        @include for-desktop {
            max-width: 500px;
        }
    }

    a {
        @include button;
        color: $deep-black;
        background-color: $pure-white;
        padding: 1em 1.4em;
        text-decoration: none;
        transition: opacity 0.3s;

        &:hover {
            color: $deep-black;
            opacity: 0.8;
            transition: opacity 0.3s;
        }
    }
}

a {
    color: $gray;
    text-decoration: none;

    &:hover {
        color: $bright-green;
        transition: 0.3s;
    }
}

.pointer {
    cursor: pointer;
}

.bold {
    font-weight: 300;
}

.uppercase {
    text-transform: uppercase;
}

.green {
    color: $bright-green;
    font-weight: 400;
}

.container {
    max-width: 100%;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

// Navigation Bar
.nav-active {
    background: rgba(13, 13, 13, 0.6);
}

.navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
}

.navbar-dark .navbar-toggler {
    border-color: none;
}

.me-auto {
    margin-left: auto;
    margin-right: initial;
}

// Reset Form Default
.form-control {
    background-color: transparent;
    border: 0.0625rem solid $bright-green;
    border-radius: 12.5rem;
    color: $pure-white;
}

// Error Codes
.page-error {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: $pure-white;

    p {
        font-size: 15px;
        font-weight: 200;
        margin-top: 10px;
    }

    a {
        color: $bright-green;
    }

    .material-symbols-outlined {
        font-size: 4rem;
    }
}

.Toastify__toast-body>div:last-child {
    font-size: 13px !important;
    font-weight: 200 !important;
}

.sticky-top {
    top: -1px !important;
}

.margin-bottom {
    margin-bottom: 12px;
}

.btn-primary {
    &:hover {
        border: 1px solid $bright-green;
    }
}
$pure-white: #FFFFFF;
$gray: #BFBFCB;
$deep-black: #0b090a;
$pale-gray: #F5F3F41A;
$bright-green: #02FF7F;
$dark-background: #0b0c0f;
$red: #BA181B;
$red-light: #ff4c4c26;
$green: #4BB543;
$medium-gray: #8080806b;
$light-gray: #C8C8C8;
$pale-border: #BBBBBB1A;
$transparent-black: #00000000;
$blue: #0069ff;
$soft-gray: #b1bdc3;
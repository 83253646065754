@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 $bright-green;
    }

    70% {
        -webkit-box-shadow: 0 0 0 0.625rem rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 $pure-white;
        box-shadow: 0 0 0 0 $bright-green;
    }

    70% {
        -moz-box-shadow: 0 0 0 0.625rem rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0.625rem rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes move {
    0% {
        transform: translateX(0);
    }

    99.99% {
        transform: translateX(-200px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    animation: bounce 0.5s;
}
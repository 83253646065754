@mixin button {
    border-radius: 200px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    padding: 0.55em 1.5em 0.5em;
    z-index: 1;
}

@mixin background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    opacity: 1;
    width: 100%;
    pointer-events: none;
}

@mixin custom-forms {
    @include for-phone {
        padding: 20px;
    }

    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        text-align: center;
        color: $pure-white;
        margin-bottom: 0;
        margin-top: -60px;
    }

    h3 {
        margin-top: 5px;
        margin-bottom: 1rem;
        text-align: center;
        color: $pure-white;
        font-weight: 200;
    }

    form {
        display: flex;
    }

    .form-control {
        min-width: 200px;

        &::placeholder {
            font-weight: 200;
            color: $pure-white;
            padding-top: 7px;
            font-size: 13px;
        }

        &:out-of-range {
            border: 0.0625rem solid $red;
        }
    }

    p {
        text-align: center;
        color: $pure-white;
        font-size: 0.8125rem;
        font-weight: 200;
        margin: 20px 0px 10px 0px;
    }

    input:focus {
        color: $pure-white;
        background-color: transparent;
    }
}

@mixin social-login-button {
    color: $pure-white;
    border: 1px solid $pure-white;
    background-color: transparent;
    margin-top: 20px;
    display: block;

    img {
        width: 17px;
        margin-right: 4px;
        margin-bottom: 3px;
    }
}

@mixin for-phone {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin for-desktop {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop {
    @media (min-width: 1800px) {
        @content;
    }
}
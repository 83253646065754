/* Copyright (C) 2022 Nino Škuflić - All Rights Reserved */
@import './Partials/mixins';
@import './Partials/variables';
@import './Partials/global';
@import './Partials/animations';

@import './Partials/Media Queries/tablet';
@import './Partials/Media Queries/desktop';
@import './Partials/Media Queries/mobile';
@import './Partials/Slider/slider';
@import './Partials/navbar';
@import './Partials/carousel';

.season-picker {
  display: flex;
  align-items: center;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  border-right: 10px solid transparent !important;
  margin-left: 10px;
  background-color: rgba(54, 54, 54, 0.2705882353);
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 200;
}

.dropdown-menu {
  position: absolute;
  background-color: #2e3047;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-item {
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #45476f;
}

.splash {
  height: min(25vw, 87vh);
  position: relative;
  top: -10vw;

  @include for-phone {
    top: -60px;
    position: relative;
  }
}

.buttons {
  margin: 20px 0;
  display: flex;
  gap: 15px;

  div {
    font-size: 10px;
    text-align: center;
    width: fit-content;

    p {
      margin: 3px 0 0 0;
    }
  }

  .material-symbols-outlined {
    // margin-right: 20px;
    color: $pure-white;
    font-size: 1.2rem;
    padding: 8px;
    vertical-align: middle;
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.05);
    border-radius: 50%;
    text-align: center;

    span {
      margin-top: 5px;
      background-color: transparent;
      display: block;
      font-size: 10px;
      font-family: 'Helvetica Neue', 'Inter', sans-serif;
    }
  }
}

// Promotional Banner
.promo {
  background: linear-gradient(90deg, #002BE7 0%, #0A0D8F 83.54%);
  padding: 15px;
  border-radius: 5px;
  margin: 15px 0 15px 0;
  width: fit-content;

  p {
    padding: 0 !important;
    margin: 0 !important;
  }

  a {
    text-decoration: underline;
    color: white;
  }

  img {
    margin-bottom: 5px;
    vertical-align: middle;
    width: 50px;
  }
}

// Content Modal (Movies, TV Shows)
.my-modal {
  .no-overflow {
    overflow: hidden;
  }

  .holder {
    &.small {
      height: 250px;
    }

    .gradient {
      min-width: 160px;
    }

    .title {
      max-width: 7rem;
      font-size: 11px;
    }

    .subtitle {
      font-size: 9px;
    }

    .image {
      height: inherit;
      width: inherit;
      min-width: 160px;

      &.not-available {
        font-size: 50px;
      }
    }
  }

  .buttons {
    margin: 0;

    p {
      font-size: 10px;
    }
  }

  @include for-desktop {
    width: 40vw;
    max-width: 40vw;
  }

  @include for-tablet {
    width: 80vw;
    max-width: 80vw;
  }

  .modal-content {
    background-color: $dark-background;
    //padding-bottom: 15px;
  }

  .background {
    /* Match the height of your ReactPlayer */
    overflow: hidden;
    display: block;
    max-width: 100%;
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    mask: linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 90%), linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 95%);
    -webkit-mask: linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 90%), linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 95%);
    -moz-mask: linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 90%), linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 95%);
    -ms-mask: linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 90%), linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 95%);
    -o-mask: linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 90%), linear-gradient(to bottom, #0b0c0f 0%, rgba(0, 0, 0, 0) 95%);
  }

  .logo {
    max-width: 150px;
    max-height: 100px;
    position: absolute;
    bottom: 30px;
    margin: 0px 20px 0px 20px;
  }

  h1 {
    color: $pure-white;
    font-weight: 200;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p {
    font-weight: 200;
    font-size: 12px;
    margin: 0;
    max-width: 90%;
  }

  .flexbox-holder-main {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @include for-phone {
      margin-bottom: 15px;
    }

    .flex-item-1 {
      flex-basis: 65%;

      .content-short-overview {
        margin: 0;
      }
    }

    .flex-item-2 {
      display: flex;
      margin: 0px 10px 0px 10px;
      flex-basis: 35%;

      span {
        color: $pure-white;
        font-size: 20px;
        padding: 5px;
        vertical-align: middle;
        cursor: pointer;
        background-color: rgb(128 128 128 / 5%);
        border-radius: 50%;
      }
    }
  }

  .flexbox-holder-content-description {
    display: flex;
    flex-direction: row;
    margin-top: 7px;

    @include for-phone {
      flex-direction: column;
    }

    .flex-item-1 {
      flex-basis: 65%;
      color: $pure-white;
    }

    .flex-item-2 {
      flex-basis: 35%;

      @include for-phone {
        padding-left: 0px;
      }

      p {
        color: $gray;
        margin-bottom: 3px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        @include for-phone {
          margin-bottom: 5px;
        }

        span {
          color: $pure-white;
        }

        a {
          color: $pure-white;

          &:hover {
            color: $bright-green;
          }
        }
      }
    }
  }

  .holder {
    position: relative;
  }

  .custom-container {
    padding: 0 1.25rem;
    margin-bottom: 0;
    padding-top: 0;
    position: relative;
    top: -20px;

    .details {
      margin-bottom: 0px;
    }

    // .holder {
    //   margin: 10px 0px 10px 0px;
    // }
  }

  .close {
    position: absolute;
    top: 13px;
    right: 13px;

    .icon {
      color: $pure-white;
      background-color: rgb(128 128 128 / 5%);
      border-radius: 50%;
      height: 30px;
      font-size: 20px;
      padding: 5px;
      width: 30px;
      cursor: pointer;
    }
  }

}

// Feedback
.feedback {
  .hide {
    color: $light-gray;
    cursor: pointer;
    //border-top: 1px solid $pale-gray;
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 0px;
    padding: 10px 20px 10px 20px;
    text-align: center;
  }

  .icon {
    color: $pure-white;
    background-color: rgba(128, 128, 128, 0.05);
    border-radius: 50%;
    font-size: 20px;
    padding: 5px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &.button {
    border-radius: 3px 3px 0 0;
    border: 0px;
    background-color: #FF3C00;
    color: $pure-white;
    font-weight: 300;
    font-size: 13px;
    padding: 3px 10px 3px 4px;
    position: fixed;
    right: 0;
    top: 43%;
    z-index: 10;
    transform: rotate(270deg);
    transform-origin: right bottom;

    @include for-desktop {
      max-width: 120px;
      max-height: 40px;
    }

    span {
      vertical-align: middle;
      margin-right: 5px;
      transform: rotate(90deg);
    }
  }

  .input-fields textarea {
    margin-top: 10px;
    resize: none;
    display: block;
    border-bottom: 0.5px solid $pale-border;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background-color: transparent;
    color: $soft-gray;
    font-weight: 200;
    font-size: 13px;
    min-width: 100%;
    border-radius: 0 !important;

    &::placeholder {
      color: $soft-gray;
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    background: #232426;
    color: $soft-gray;

    h1 {
      font-size: 20px;
      font-weight: 200;
      padding: 0 20px 0 20px;
      margin-bottom: 2px;

      @include for-phone {
        padding: 0 50px 5px 20px;
      }
    }

    p {
      padding: 0 20px 0 20px;
      font-size: 13px;
      font-weight: 300;
    }

    .form {
      padding: 0px 20px 30px 20px;
      //text-align: center;

      .material-symbols-outlined {
        font-size: 40px;

        @include for-phone {
          font-size: 30px;
        }
      }
    }
  }

  .custom-button {
    border-radius: 0;
    width: 100%;
  }

  .loading {
    height: 20vh;
  }

}

// Homepage Hero
.homepage-holder {
  @include for-desktop {
    background-image: linear-gradient(to top, $dark-background 0%, $transparent-black 100%), url('https://image.tmdb.org/t/p/original/nDOIsgCYZqEMvomSR6t9QUIPZJS.jpg');
    background-position: center center;
    background-size: cover;
    height: 80vh;
    display: flex;
    align-items: center;
    margin-top: -60px;
  }

  @include for-phone {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to top, $dark-background 0%, $transparent-black 100%), url('https://image.tmdb.org/t/p/w1920_and_h1080_multi_faces/nDOIsgCYZqEMvomSR6t9QUIPZJS.jpg');
    margin-top: -60px;
    height: 60vh;
    display: flex;
    align-items: center;

    .no-gutter {
      min-height: 50vh;
    }

  }
}

// .homepage-hero {
//   .col-md-4 {
//     display: flex;
//     align-items: center;
//     padding-left: 20px;
//     padding-right: 20px;
//   }

//   .manage,
//   .image {
//     min-height: 100vh;
//   }

//   .bg-image {
//     height: 100%;
//     overflow: hidden;
//     padding-right: 0;
//     margin-top: -60px;
//   }
// }

// Global Buttons
.custom-button {
  @include button;

  &.rectangle {
    border-radius: 0px;
  }

  color: $dark-background;
  background-color: $bright-green;
  border: 0.0625rem solid $bright-green;

  &:hover,
  &:active {
    color: $dark-background !important;
    background-color: $bright-green !important;
  }

  &.animated {
    animation: pulse 2s infinite;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: pulse;
  }

  &.inverted {
    color: $bright-green;
    border: 0.0625rem solid $bright-green;
    background-color: transparent;

    &:hover {
      color: $dark-background;
      border: 0.0625rem solid $bright-green;
      background-color: $bright-green;
      transition: 0.3s;
    }
  }
}

.holder {
  .animated {
    animation: pulse 2s infinite;
  }
}

// Footer
.footer {
  padding: 2rem 2rem;

  .col {
    .material-symbols-outlined {
      vertical-align: middle;
      color: $pure-white;
      margin-bottom: 2px;
    }
  }

  @include for-phone {
    padding: 2rem 1.5rem;
  }

  @include for-tablet {
    padding: 2rem 1.5rem;
  }

  z-index: 5;
  border-top: 0.0313rem solid $pale-gray;
  margin-top: auto;

  h5 {
    color: $bright-green;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-transform: uppercase;
    margin-top: 0;
  }

  h4 {
    color: $bright-green;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.75rem;
    text-transform: uppercase;
    margin-bottom: 0rem;
    margin-top: 1rem;
    line-height: 25px;
  }

  a {
    color: $soft-gray;
    font-size: 10px;
  }

  .disclaimer {
    color: $soft-gray;
    line-height: 1.4em;
    font-size: 0.625rem;
    font-weight: 200;
    margin-top: 0px;
    text-align: justify;

    .country {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .material-symbols-outlined {
        font-size: 15px;
        margin-right: 3px;
        margin-left: -2px;
      }
    }
  }

  .manage-preferences {
    font-weight: 200;
    font-size: 13px;
    cursor: pointer;
    color: $soft-gray;

    &:hover {
      color: $bright-green;
    }
  }

  .globe {
    width: 0.875rem;
    margin-bottom: 0.125rem;
    margin-right: 0.1875rem;
  }

  ul {
    padding-left: 0;
  }

  li {
    list-style: none;
    color: $pure-white;
    line-height: 20px;

    a,
    span,
    button {
      border: 0;
      background-color: transparent;
      padding: 0;
      font-size: 13px;
      color: $soft-gray;
      font-weight: 200;

      &:hover {
        color: $bright-green;
      }
    }
  }

  @include for-phone {
    .row {
      flex-direction: column;
    }
  }

  form {
    h4 {
      color: $bright-green;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    p {
      font-weight: 200;
      font-size: 11px;
      color: $soft-gray;
      margin-top: 10px;
      margin-bottom: 0;
      line-height: 15px;

      @include for-desktop {
        width: 470px;
      }
    }

    div {
      @include for-desktop {
        width: 49%;
      }
    }

    input {
      padding: 13px;
      border: 0;
      width: 55%;
      font-size: 12px;
      border-radius: 0 !important;
      background-color: $pure-white;

      @include for-phone {
        width: calc(100% - 110px);
      }
    }

    .custom-button {
      padding: 13px 25px 13px 25px;
      border-radius: 0px;
      border: 0;
      font-size: 12px;

      &:hover {
        background-color: $bright-green;
        color: $dark-background;
        transition: 0.3s;
      }
    }

    #green {
      display: none;
    }

  }

}

.loading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 50vh;
}

// Search Component & Trending Component
.results-indicator {
  .material-symbols-outlined {
    vertical-align: bottom;
    color: $pure-white;
  }

  .scroll-tray {
    &.main {
      justify-content: center;
      max-width: 20%;
      margin: 0 auto;

      @include for-phone {
        max-width: 63%;
      }
    }
  }

  .indicator {
    background-color: transparent;
    border: 0px;
    color: $bright-green;
    font-weight: 200;
    width: fit-content;
    height: fit-content;

    padding: 0px 0px;
    min-width: 24px;
    min-height: 24px;

    &:hover {
      background-color: #1e2a3ad3;
      border-radius: 16px;
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition-duration: 250ms, 250ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
      transition-delay: 0ms, 0ms;
      transition-property: color, background-color;
    }
  }

  .arrow {
    margin-bottom: 3px;
  }

  .active {
    color: rgb(255, 255, 255);
    background-color: #1e2a3ad3;
    border-radius: 16px;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 250ms, 250ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: 0ms, 0ms;
    transition-property: color, background-color;
  }

  text-align: center;
  color: $pure-white;
  font-size: 0.8125rem;
  font-weight: 200;
  background-color: transparent;
  border: 0rem;
  padding: 0rem;
  margin: 2rem 0.3125rem 0 0.3125rem;

  a {
    color: $bright-green;
  }
}

// FAQ on App Component
.faq {
  border-bottom: 0;
  //border-top: 0.0313rem solid rgba(187, 187, 187, 0.1019607843);
  padding-top: 1.25rem;
  padding-bottom: 3rem;

  h4 {
    margin-top: 0;
    color: $pure-white;
    font-weight: 200;
    font-size: 3em;
    text-align: center;
    padding: 1.25rem;
  }

  .accordion {
    list-style: none;
    padding: 0;
    width: 50%;
    margin: auto;

    @include for-phone {
      width: 90%;
    }

    @include for-tablet {
      width: 70%;
    }
  }

  .accordion_item {
    padding-top: 10px;

    .title {
      max-width: 90%;
    }
  }

  .button {
    width: 100%;
    background-color: $pale-gray;
    color: $pure-white;
    text-align: left;
    font-weight: 200;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 18px 8px;
    border: none;
    padding-left: 20px;
    padding-right: 20px;

    &:hover {
      transition-duration: 250ms;
      transition-property: background-color;
      transition-timing-function: cubic-bezier(0.9, 0, 0.51, 1);
      background-color: $medium-gray;
    }
  }

  .answer {
    background-color: $pale-gray;
    color: $pure-white;
    padding: 20px;
    font-weight: 200;
    font-size: 14px;
    margin-top: 1px;

    a {
      font-weight: 200;
      color: $pure-white;
      text-decoration: underline;
    }
  }

  .answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }
}

// Trending Homepage Component
.homepage-card {
  margin: 3vw 0;

  // .arrow {
  //   padding: .5rem;
  // }

  .arrow-buttons {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
  }

  &:hover {
    .arrow-buttons {
      visibility: visible;
      opacity: 1;
    }
  }

  h3 {
    padding-left: 20px;
    margin-bottom: 1px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    letter-spacing: -1px;
    line-height: 23px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
  }

  p {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 200;
    letter-spacing: 0px;
    max-width: 500px;
    font-size: 13px;
    margin-bottom: 14px;
  }

}

.custom-container-background {
  position: absolute;
  z-index: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  mask: linear-gradient(to bottom, $dark-background 30%, rgba(0, 0, 0, 0) 80%),
    linear-gradient(to bottom, $dark-background 50%, rgba(0, 0, 0, 0) 80%);
  mask-size: 100% 100%;
  mask-position: bottom;
  mask-repeat: no-repeat;
  mask-composite: intersect;

  .navbar-background-image {
    @include background;
    mask:
      linear-gradient(to bottom, $dark-background 10%, $transparent-black 95%),
      linear-gradient(to left, $dark-background 10%, $transparent-black 95%);
    mask-size: 110% 110%;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-composite: intersect;
  }

}

.main-background {
  @include background;

  &#small {
    @include for-desktop {
      width: 65% !important;
      right: 0;
    }
  }
}

.homepage-background {
  @include background;
  mask:
    linear-gradient(to bottom, $dark-background 0%, $transparent-black 100%);
}

.main-button {
  color: $bright-green !important;
  background-color: transparent;
  border: 0rem;
  padding: 0rem;
}

// Arrow Buttons
.arrow-buttons {
  border: 0rem;
  position: absolute;
  z-index: 9;
  opacity: 1;

  .material-symbols-outlined {
    color: $pure-white;
  }
}

.arrow {
  border: solid $pure-white;
  border-width: 0 0.1875rem 0.1875rem 0;
  display: inline-block;
  padding: 0.1875rem;
}

.right {
  margin-left: 5px;
  margin-right: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-bottom: 1px;
}

.up {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  padding: 0.25rem;
}

.left {
  margin-left: 5px;
  margin-right: 5px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-bottom: 1px;
}

/* 
* CONTENT DETAILS
*/

.custom-container {
  padding: 0rem 2rem;

  @include for-phone {
    padding: 0rem 1.5rem;
  }

  @include for-tablet {
    padding: 0rem 1.5rem;
  }

  color: $pure-white;
  padding-bottom: 0rem;
  position: relative;
  font-size: 0.8125rem;
  font-weight: 200;
  margin-bottom: 2rem;

  .content-rating {
    border: 1px solid $pure-white;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    padding: 1px 3px;
  }

  .content-image {
    &.person {
      margin-top: .5rem;
    }

    &.not-available {
      font-size: 65px;
      width: inherit;
      height: inherit;

      color: $gray;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    width: 248px;
    height: 371px;
    min-width: 248px;
    min-height: 371px;
    //border-radius: 5px;
    background-color: #ffffff1a;
  }

  @include for-phone {
    .content-image {
      width: 100%;
      height: 100%;
      // min-width: 350px;
      // min-height: 525px;
    }

  }

  .content-overview {
    font-size: 0.8125rem;
    width: 60%;
    font-weight: 200;
    margin-bottom: 0px;

    p {
      margin-bottom: 10px;
    }

    @include for-phone {
      & {
        width: 100%;
      }
    }

    @include for-tablet {
      & {
        width: 50%;
      }
    }

  }

  ul {
    margin: 0;
  }

  ul.dashed {
    list-style-type: none;
    padding-left: 0.3125rem;
  }

  ul.dashed>li {
    text-indent: -0.3125rem;
  }

  ul.dashed>li:before {
    content: '- ';
    text-indent: -0.3125rem;
  }

  .content-short-overview {
    color: $bright-green;

    margin: 0 0 15px 0;
    padding: 0;

    &.subtitle {
      color: $pure-white;
      margin: 0;
    }

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: inline;

      &:not(:last-child):after {
        content: ' — ';
        text-indent: -0.3125rem;
      }
    }

  }

  .ratings {
    display: flex;
    margin-bottom: 0px;
    gap: 30px;

    p {
      margin-bottom: 0;
    }

    &.icon {
      width: 60px;
      margin-top: 20px;
      display: inline;
    }

    @mixin rating-icon {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    // .TheMovieDatabase::after {
    //   @include rating-icon();
    //   width: 90px;
    //   vertical-align: text-bottom;
    //   content: url('../Assets/Images/tmdb.svg');
    // }

    // .InternetMovieDatabase::after {
    //   @include rating-icon();
    //   width: 30px;
    //   content: url('../Assets/Images/imdb.svg');
    // }

    // .RottenTomatoes::after {
    //   @include rating-icon();
    //   width: 50px;
    //   content: url('../Assets/Images/rotten-tomatoes.svg');
    // }

    // .Metacritic::after {
    //   @include rating-icon();
    //   width: 65px;
    //   content: url('../Assets/Images/metacritic.svg');
    // }

    @include for-phone {
      flex-direction: column;
    }

    h6 {
      margin-top: 5px;
    }

  }

  .where-to-watch {
    display: flex;
    margin-bottom: 0px;
    gap: 30px;


    h6 {
      margin-top: 5px;
    }

    @include for-phone {
      flex-direction: column;
    }

  }

  .details {
    font-size: 0.8125rem;
    font-weight: 200;
    margin-top: 0;

    .collection {
      color: $bright-green;
    }

    h5 {
      text-transform: capitalize;
    }

    &.episode {
      margin-top: 0rem;
    }

    &.content-tagline {
      margin-top: 0px;
      font-style: italic;
    }

  }

  .video {
    width: 514px;
    height: 100%;
    margin-right: 1rem;

    @include for-phone {
      width: 21.875rem;
      height: 100%;
    }
  }

  .network>img {
    //filter: brightness(0) saturate(100%) invert(100%) sepia(79%) saturate(20%) hue-rotate(357deg) brightness(105%) contrast(104%);
  }

  .network>img:first-child {
    display: initial;
  }

  .network img {
    margin-bottom: 10px;
    // display: none;
    // position: absolute;
    // top: 1.25rem;
    // right: 1.25rem;
    //max-height: 50px;
    max-height: 100px;
    max-width: 400px;
    opacity: 1;

    @include for-phone {
      max-width: 150px;
    }
  }

  .slider-buttons {
    margin-left: 10px;
    display: inline;
  }

}

.holder-container {
  background: linear-gradient(90deg, hsl(225, 15%, 5%) 10%, hsla(225, 15%, 5%, .98) 20%, hsla(225, 15%, 5%, .97) 25%, hsla(225, 15%, 5%, .95) 35%, hsla(225, 15%, 5%, .94) 40%, hsla(225, 15%, 5%, .92) 45%, hsla(225, 15%, 5%, .9) 50%, hsla(225, 15%, 5%, .87) 55%, hsla(225, 15%, 5%, .82) 60%, hsla(225, 15%, 5%, .75) 65%, hsla(225, 15%, 5%, .63) 70%, hsla(225, 15%, 5%, .45) 75%, hsla(225, 15%, 5%, .27) 80%, hsla(225, 15%, 5%, .15) 85%, hsla(225, 15%, 5%, .08) 90%, hsla(225, 15%, 5%, .03) 95%, hsla(225, 15%, 5%, 0));
}

.read-more {
  mask-image: linear-gradient($deep-black calc(100% - 3.125rem), transparent);
  -webkit-mask-image: linear-gradient($deep-black calc(100% - 3.125rem),
      transparent);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0.1875rem;
}

.read-less {
  mask-image: none;
  -webkit-mask-image: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  -webkit-box-orient: vertical;
  margin-bottom: 0.1875rem;
}

.read-more-button {
  color: $bright-green;
  font-size: 0.6rem;
  border: 0rem;
  background-color: transparent;
  text-transform: uppercase;
  margin-bottom: 0rem;
  margin-left: 0rem;
  padding-left: 0rem;
  font-weight: 400;
}

.adult-content {
  width: 2.5rem;
  z-index: 999;
  position: absolute;
  margin-left: 0.3125rem;
  margin-top: 0.3125rem;
}

.adult-content-warning {
  position: absolute;
  width: 1.875rem;
  z-index: 1;
  margin-top: 0.3125rem;
  margin-left: 0.3125rem;
  color: $pure-white;
  background: rgba(31, 31, 31, 0.75);
  border-radius: 50%;
  font-size: 20px;
  padding: 5px;
}


.top-bar {
  background: $blue;
  color: $pure-white;
  font-size: 12px;
  text-align: left;
  padding: 2px 4rem;

  @include for-phone {
    padding: 4px 1.5rem;
  }

  @include for-tablet {
    padding: 4px 1.5rem;
  }

  font-weight: 200;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
  display: flex;

  .max-logo {
    max-width: 50px;
    margin-right: 5px;
    vertical-align: sub;
  }

  p {
    margin-bottom: 0rem !important;
  }

  .close {
    cursor: pointer;
    padding: 0 13px;
    //filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(47deg) brightness(112%) contrast(114%);
  }
}

// Back To Top Button
#top-button {
  color: $pure-white;
  z-index: 80;
  position: fixed;
  bottom: 1.25rem;
  right: 1.875rem;
  border: none;
  outline: none;
  background-color: transparent;
  display: none;

  background-color: rgba(245, 243, 244, 0.1019607843);
  border-radius: 50%;

  width: 30px;
  height: 30px;
  padding: 0px;

  .material-symbols-outlined {
    vertical-align: bottom;
  }

}

/*
* RATE
*/

.rate {
  .material-symbols-outlined {
    font-size: 30px !important;
  }
}

/*
* SAFE SEARCH
*/

.safe-search {
  @include custom-forms;

  .custom-button {
    margin-top: 20px;
  }
}

/*
* COUNTRY SELECTION
*/

.country-selection {
  @include custom-forms;

  form {
    margin-top: 20px;
    justify-content: center;
  }

  .select {
    min-width: 200px;

    @include for-desktop {
      min-width: 300px;
    }

    z-index: 6;
  }
}

/*
* SHARE
*/

.social-icon {
  width: 1.25rem;
  margin-right: 0.9375rem;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(47deg) brightness(112%) contrast(114%);
}

.social-share {
  margin-top: 0.9375rem;
}

.scroll-tray {
  position: relative;
  height: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  animation: none;
  display: flex;
  gap: 10px;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .scroll-tray>div,
  .scroll-tray>a {
    flex: 0 0 auto;
    // width: fit-content;
    scroll-snap-align: start;
    overflow: hidden;
    cursor: pointer;
  }

  .arrow-buttons {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;

    &.arrow-left {
      //background: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
      background-color: transparent;
    }

    &.arrow-right {
      right: 0;
      //background: linear-gradient(-90deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
      background-color: transparent;
    }

    height: -webkit-fill-available;
  }

  &:hover {
    .arrow-buttons {
      visibility: visible;
      opacity: 1;
    }
  }

  .wrapper {
    position: relative;
    text-align: left;
  }

  .gradient {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: inherit;
    //background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 4.58%, rgba(0, 0, 0, 0) 69.61%), linear-gradient(360deg, rgb(0 0 0 / 93%) 4.71%, rgba(0, 0, 0, 0) 71.49%);
    // min-width: 208px;

    &.favorites {
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 15px 10px 15px;
    color: $pure-white;

    &.episode-info {
      display: flex;
      flex-direction: column;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
      }
    }
  }

  .holder {
    height: 376px;
    width: 250px;

    &.episodes {
      height: 393px;
      width: 622px;

      @include for-phone {
        width: calc(100vw - 3rem);
        height: 205px;
      }
    }

    @include for-phone {
      height: 250px;
      width: inherit;
    }

    position: relative;
    text-align: left;
    transition: transform .3s ease-out;
  }

  .image {
    width: inherit;
    height: inherit;
    object-fit: cover;
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 4.58%, rgba(0, 0, 0, 0) 90%),
      linear-gradient(180deg, rgba(0, 0, 0, 0.93) 4.71%, rgba(0, 0, 0, 0) 95%);
    /* Ensure the mask applies correctly */
    -webkit-mask-composite: source-over;
    mask-composite: add;
    background-color: rgba(54, 54, 54, 0.2705882353);
    display: inline-block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;

    @include for-phone {
      max-width: 100%;
    }

    &.favorites {
      height: 100%;
    }
  }

  .not-available {
    font-size: 65px;
    width: inherit;
    height: inherit;
    color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .holder:has(.not-available) {
    .gradient {
      display: none !important;
    }

    .image {
      mask-image: none;
    }
  }

  .title {
    clear: both;
    float: left;
    margin-bottom: 3px;
    max-width: 10rem;
    font-size: 12px;
    font-weight: 200;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    white-space: nowrap;
    display: inline-block;

    &.longer {
      max-width: 25rem !important;
    }
  }
}

.subtitle {
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  max-width: 12rem;
  //float: left;
  display: block;

  &.description {
    text-wrap: balance;
    margin-bottom: 5px;
    margin-top: 4px;
    display: block;
    max-width: 100%;
  }
}

// ACCOUNT MANAGEMENT
.account {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  padding: 20px;

  .passwordless {
    background: #f3f3f30d;
    padding: 15px;
    border-radius: 5px;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 15px;
    text-align: left;

    color: $light-gray;

    .manual {
      cursor: pointer;
      text-decoration: underline;
    }

    .material-symbols-outlined {
      vertical-align: middle;
      margin-right: 4px;
    }
  }

  .profile-picture-holder {
    width: fit-content;
    margin: auto;
  }

  .issues {
    font-weight: 200;
    font-size: 13px;
    margin-bottom: 0rem;
    text-align: center;
    margin-top: 20px;
  }

  .disclaimer {
    text-align: center;
    margin-top: 0;
  }

  .verified {
    font-size: 16px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: 2px;
    color: $bright-green;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.material-symbols-outlined {
      font-variation-settings:
        'FILL' 1,
    }
  }

  .user {
    border-radius: 200px;
    min-width: 80px;
    background-color: $gray;
    min-height: 80px;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  // Separator
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 200;
    font-size: 12px;
    margin-top: 20px;
    color: $light-gray;
    margin-bottom: 20px;
  }

  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-top: 0.0313rem solid $light-gray;
  }

  .separator:not(:empty)::before {
    margin-right: .6em;
  }

  .separator:not(:empty)::after {
    margin-left: .6em;
  }

  #delete {
    margin-top: 20px;

    .confirm {
      color: $red;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 20px;
      display: block;
      cursor: pointer;
    }
  }

  #delete-account-disclaimer {
    margin-top: 5px;
  }

  p {
    font-weight: 200;
    font-size: 13px;
    margin-bottom: 0rem;
    text-align: center;
  }

  h1 {
    text-shadow: 0 1px 2px rgb(0 0 0 / 57%);
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 5px;
    letter-spacing: 0px;
    text-align: center;
  }

  .field {
    border-bottom: 0.5px solid $light-gray;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background-color: transparent;
    color: #BFBFCB;
    font-weight: 200;
    font-size: 13px;
    min-width: 300px;
    min-height: 40px;
    border-radius: 0 !important;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-weight: 200;
      font-size: 13px;
      color: $gray;
    }
  }

  .password-reset {
    margin-top: 10px;
  }

  .help-text {
    color: $gray;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: -5px;
    text-align: left;
  }

  .input-field {
    width: fit-content;
    margin: auto auto 20px auto;
  }

  .button {
    color: $dark-background;
    border-color: transparent;
    background-color: $bright-green;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    padding: 0.55em 1.5em 0.5em;
    min-width: 300px;
    min-height: 40px;
    margin-top: 5px;
    border-radius: 20px;

    &.delete {
      color: $red;
      margin-top: 15px;
      border: 1px solid $red;
      background-color: transparent;
      transition: 0.3s;
      display: block;
      margin: 0 auto;
      margin-top: 20px;

      &:hover {
        color: white;
        border: 1px solid $red;
        background-color: $red;
        transition: 0.3s;
      }
    }

    &.logout {
      margin-top: 10px;
    }

    &.inverted {
      display: block;
      margin: auto;
      border: 1px solid $bright-green;
      background-color: transparent;
      color: $bright-green;
    }

  }

  .help {
    margin-bottom: 30px;
  }

  .tab-list {
    margin-top: 25px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(245, 243, 244, 0.1019607843);
    border-top: 1px solid rgba(245, 243, 244, 0.1019607843);
    padding: 0px 20px 0px 20px;
    cursor: pointer;
    width: 100vw;
    text-align: center;

    @include for-tablet {
      justify-content: center;
    }

    @include for-desktop {
      width: 100%;
    }
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    font-weight: 300;
    font-size: 13px;
    color: $pure-white;
    text-transform: uppercase;

    &:hover {
      color: $bright-green;
      transition: 0.3s;
    }
  }

  .tab-content {
    text-align: center;
    padding: 20px;
  }

  .tab-list-active {
    font-weight: 600;
    font-size: 13px;
    color: $bright-green;
    background-color: transparent;
    border-bottom: 3px solid $bright-green !important;
    border-width: 1px 1px 0 1px;
    transition: 0.2s ease-in;
    border-radius: 0px;
  }

  .google {
    @include social-login-button;
    margin: auto;
    margin-bottom: 10px;
  }

  .twitter {
    @include social-login-button;
    margin: auto;
    margin-bottom: 10px;
  }

  .microsoft {
    @include social-login-button;
    margin: auto;
    margin-bottom: 10px;
  }

  .github {
    @include social-login-button;
    margin: auto;
    margin-bottom: 10px;
  }

  .error {
    font-size: 12px;
    padding: 10px;
    background-color: $red-light;
    color: $red;
    border: 1px solid $red;
  }

  .error-placeholder {
    font-size: 13px;
    padding: 15px 10px 15px 10px;
    background-color: $red-light;
    color: $red;
    display: none;
    margin-top: 30px;
  }

  .login-form {
    margin-top: 30px;
    text-align: center;
  }

  form {
    margin-top: 30px;
  }

  .upload {
    margin: 20px 0px 10px 0px;

    .inverted {
      cursor: pointer;
      padding-top: 10px;
      text-align: center;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }

    .file {
      display: none;
    }

  }

  .uploading {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 13px;
    color: $bright-green;
    font-weight: 700;
    visibility: hidden;
  }

}

#firebase-error {
  text-align: center;
  color: $pure-white;
  display: none;

  p {
    font-size: 0.875rem;
    font-weight: 200;
    margin: 10px 10px 30px 10px;
  }
}

.disclaimer {
  text-align: center;
  display: block;
  font-size: 10px;
  font-weight: 200;
  letter-spacing: 0px;
  line-height: 1.5;
  text-transform: none;
  color: $gray;
  margin-top: 20px;

  @include for-phone {
    text-align: left;
  }
}

.add-to-favorites {
  position: absolute;
  width: 35px;
  z-index: 1;
  margin-top: 5px;
  margin-left: 208px;
  cursor: pointer;
  padding: 3px;
}

.remove-from-favorites {
  visibility: hidden;
  position: absolute;
  width: 20px;
  z-index: 1;
  margin-top: 5px;
  margin-left: 175px;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  cursor: pointer;
  background-color: $medium-gray;
  border-radius: 200px;
  padding: 3px;

  @include for-phone {
    visibility: visible;
    opacity: 0.7;
    background-color: transparent;
  }
}

.ribbon-holder {
  position: relative;
}

.ribbon {
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;

  .material-symbols-outlined {
    margin-right: 20px;
    color: #FFF;
    font-size: 20px;
    padding: 5px;
    vertical-align: middle;
    cursor: pointer;
    background-color: rgb(0 0 0 / 40%);
    border-bottom-right-radius: 10px;
  }
}

.new {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  font-weight: 400;
  padding: 3px 13px 3px 13px;
  border-radius: 0px !important;
  border-bottom-right-radius: 7px !important;
  background: rgba(31, 31, 31, 0.75);
  color: rgba(255, 255, 255, 0.75);
}

.holder:hover .remove-from-favorites {
  visibility: visible;
  opacity: 0.7;
  background-color: $medium-gray;
  border-radius: 200px;
  padding: 3px;

  @include for-phone {
    opacity: 0.7;
    background-color: transparent;
  }
}

// Sorting from Genres
.sort {
  color: $gray;
  font-size: 0.8125rem;
  font-weight: 200;
  background-color: rgba(54, 54, 54, 0.2705882353);
  padding: 5px 10px 5px 10px;
  border-radius: 25px;

  &.active {
    color: $bright-green;
  }

  .grid {
    display: flex;

    div {
      margin-right: 30px;
    }
  }

}

.special-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.turnstile {
  margin-top: 10px;
}

.reviews {
  display: flex;
  gap: 10px;

  .date {
    position: absolute;
    top: 6px;
    right: 10px;
    opacity: 0.3;
  }

  .verified {
    font-size: 14px;
    vertical-align: middle;
    margin-bottom: 3px;
    margin-left: 2px;
    color: $bright-green;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.material-symbols-outlined {
      font-variation-settings: 'FILL' 1;
    }
  }

  .review {
    overflow-wrap: break-word;
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 0px;

    &.read-more {
      mask-image: linear-gradient($deep-black calc(100% - 3.125rem), transparent);
      -webkit-mask-image: linear-gradient($deep-black calc(100% - 3.125rem),
          transparent);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 0.1875rem;
    }

    &.read-less {
      mask-image: none;
      -webkit-mask-image: none;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: unset;
      line-clamp: unset;
      -webkit-box-orient: vertical;
      margin-bottom: 0.1875rem;
    }
  }

  .holder {
    position: relative;
    background-color: #36363645;
    width: 400px !important;
    height: auto;
    padding: 20px;
    min-width: 310px;
    margin-top: 0px;

    @include for-phone {
      max-width: calc(100vw - 3rem);
    }
  }
}

.skeleton-holder {
  position: relative;
  background: #f5f3f41a;
  overflow: hidden;

  .skeleton {
    position: absolute;
    width: inherit;
    height: inherit;
    background: linear-gradient(90deg, #161616 25%, #1b1b1e 50%, #161616 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;

    @include for-phone {
      padding: 8px;
    }
  }

  .title {
    height: 16px;
    margin-bottom: 32px;
    background: rgba(54, 54, 54, 0.2705882353);

    @include for-phone {
      width: 80px;
    }
  }

  .subtitle {
    height: 16px;
    margin-bottom: 8px;
    background: rgba(54, 54, 54, 0.2705882353);

    @include for-phone {
      width: 100px;
    }
  }

  @keyframes loading {
    from {
      background-position: 200% 0;
    }

    to {
      background-position: -200% 0;
    }
  }
}
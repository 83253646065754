.navbar {
    color: $light-gray;
    transition: 0.3s ease-in;
    // background: #0d0d0d99;
    //backdrop-filter: blur(22px);
    background: linear-gradient(rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.45) 50%, rgba(0, 0, 0, 0.25) 75%, rgba(0, 0, 0, 0) 100%);
    background-color: transparent;
    padding: 0rem 2rem;

    @include for-phone {
        padding: 0;
    }

    @include for-tablet {
        padding: 0rem;
    }

    height: 60px;

    //position: absolute;
    width: 100%;

    &.colorChange {
        background-color: transparent;
    }

    .navbar-brand {
        flex-grow: 1;
    }

    .logo {
        max-width: 100px;
    }

    .container {
        align-items: center;
        justify-content: space-between;
        display: flex;
        padding: 0px;

        @include for-phone {
            padding: 0 1.5rem;
        }

        @include for-tablet {
            padding: 0rem 1.5rem;
        }
    }

    .nav-search {
        min-width: 40vw;
        height: 60px;
        margin: 0;
        padding: 20px;
        border: 0;

        background-color: #141414;
        color: $pure-white;
        font-weight: 200;
        font-size: 13px;
        transition: transform 0.2s ease-in-out;
        transform: translateX(0);

        &::placeholder {
            font-weight: 200;
            color: #535353;
            font-size: 13px;
        }
    }

    .overlay {
        height: 100vh;
        width: 100vw;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        background-color: $dark-background;
        overflow-x: hidden;
        overflow-y: hidden;
        transition: 0.5s;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease, visibility 0.5s ease;

        &.open {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.5s ease, visibility 0.5s ease;
        }

        a {
            color: $soft-gray;
            text-decoration: none;
            font-size: 1rem;
            font-weight: 200;
            display: block;
            transition: 0.3s;

            &:focus,
            &:hover {
                color: $bright-green;
            }
        }

        .close-button {
            position: absolute;
            top: 3rem;
            right: 4rem;

            @include for-phone {
                top: 2rem;
                right: 1.5rem;
            }

            z-index: 1000;
            color: $pure-white !important;
        }

        .country-selector {
            position: absolute;
            bottom: 0;
            left: 4rem;
            font-size: 13px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .material-symbols-outlined {
                font-size: 15px;
                margin-right: 3px;
                margin-left: -2px;
            }
        }
    }

    .overlay-content {
        position: relative;
        text-align: left;
        z-index: 20;
        height: 100vh;
        display: flex;
        flex-direction: row;
        background-color: transparent !important;

        .navigation-content {
            flex: 0 1 auto;
            overflow-y: scroll;
            display: flex;
            gap: 8vw;
            margin: 3rem 4rem;

            @include for-phone {
                margin: 2rem 1.5rem;
                flex-direction: column;
            }

            @include for-tablet {
                flex-direction: column;
            }
        }

        .background-content {
            flex: 1 1 auto;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 75%),
            linear-gradient(to left, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 100%);
            mask-size: 100% 100%;
            mask-position: bottom,
            left;
            mask-repeat: no-repeat;
            mask-composite: intersect;
        }

        .material-symbols-outlined {
            font-size: 3rem;
            margin-bottom: 10px;
            display: block;
            color: $bright-green;
            user-select: none;
        }

        @include for-phone {
            flex-direction: column;
        }

        h1 {
            font-size: 1.5rem;
            font-weight: 200;
            color: $bright-green;
            margin-top: 0;
        }

        h2 {
            font-size: 16px;
            font-weight: 200;
            color: $bright-green;
        }
    }
}

@mixin dropdown {
    max-width: calc(40vw + 106px);
    min-width: calc(40vw + 106px);
    background-color: #141414;
    max-height: 50vh;
    overflow-y: scroll;
    position: absolute;
    border-top: 1px solid $pale-border;

    p {
        margin: 20px;
        font-size: 13px;
        font-weight: 200;
        color: #535353;
    }
}

.nav-account,
.nav-favorites,
.nav-menu {
    &.material-symbols-outlined {
        padding: 13px;
        color: $pure-white;
        cursor: pointer;
        font-size: 27px;
        height: 60px;
        padding-top: 15px;
        user-select: none;
    }
}

// Input Field
.input {
    //background-color: #141414;
    display: flex;
    flex-direction: row;
    position: relative;

    .material-symbols-outlined {
        padding: 13px;
        color: $pure-white;
        cursor: pointer;
        font-size: 27px;
        height: 60px;
        padding-top: 15px;
        user-select: none;
    }

    .active {
        border-bottom: 3px solid $pure-white;
    }
}

.navbar .container:has(.active-mobile) {
    .input {
        background-color: #141414;
    }
}

@include for-phone {
    .navbar .container:has(.active-mobile) {
        & {
            padding: 0;
            position: static;
        }

        .input {
            background-color: #141414;
        }

        .results,
        .speech {
            max-width: 100vw;
            min-width: 100vw;
            max-height: calc(100vh - 60px);
            min-height: calc(100vh - 60px);
        }

        .speech {
            .icon {
                width: 100vw;
                position: absolute;
                bottom: 10px;
            }
        }

        .nav-search {
            width: calc(100vw - (106px));
        }

        .navbar-brand,
        .nav-account,
        .nav-favorites,
        .nav-menu {
            display: none !important;
        }
    }
}

// Voice Search
.speech {
    @include dropdown;
    border-top: none;
    border-image: linear-gradient(to right, #061FFF 25%, #009CFF 25%, #06F3FF 50%, #0586FA 50%, #0586FA 75%, #061FFF 75%) 5;
    border-bottom: 3px solid;

    h1 {
        margin: 20px;
        font-weight: 200;
        color: $pure-white;
        font-size: 18px;
    }

    .icon {
        &.material-symbols-outlined {
            font-size: 3rem;
            margin-top: 3rem;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }
    }

    .background {
        filter: blur(100px);
        background-image: linear-gradient(to top, #071FFF 0%, #00D4FF 100%);
        width: 100%;
        height: 50%;
        position: absolute;
        bottom: 0;
        opacity: 0.1;
    }

}


// Search Results
.results {
    @include dropdown;

    &::-webkit-scrollbar {
        width: 3px;
        display: inline;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #272727;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $bright-green;
    }

    p {
        &:first-child {
            a {
                color: $light-gray;
            }
        }
    }

    .holder {
        display: flex;
        margin: 5px 20px 5px 20px;
        padding: 20px 0 20px 0;
        border-bottom: 1px solid $pale-border;

        &:first-of-type {
            margin-top: -20px;
        }

        &:last-of-type {
            border-bottom: 0px;
            margin-bottom: 0px;
        }
    }

    img {
        &.person {
            max-width: 150px;
        }

        max-width: 150px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.1019607843);
    }

    .details {
        margin-left: 20px;

        h1 {
            font-size: 14px;
            font-weight: 300;
            margin-top: 0;

            a {
                color: $pure-white;
            }
        }

        p {
            font-size: 12px;
            font-weight: 200;
            color: $gray;
            margin: 0;
            padding: 0;

            &.overview {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                line-clamp: 3;
                -webkit-box-orient: vertical;
                margin-bottom: 10px;
            }
        }

        .content-short-overview {
            color: $gray;
            font-size: 12px;
            font-weight: 200;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0;
                list-style-type: none;
                display: inline;

                &:not(:last-child)::after {
                    content: ' — ';
                    text-indent: -0.3125rem;
                }
            }

        }
    }
}

#mic {
    position: relative;

    &:after {
        content: '';
        height: 28px;
        width: 1px;
        position: absolute;
        right: 0;
        top: 14px;
        background-color: #444444;
    }
}
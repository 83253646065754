@media screen and (min-device-width: 1200px) and (max-resolution: 192dpi) {

    .footer h5 {
        pointer-events: none;
    }

    .main-item-holder.home-page {
        height: inherit;
    }

    .hide-desktop {
        display: none !important;
    }

    .footer {
        .col {
            .material-symbols-outlined {
                display: none;
            }
        }
    }

    .collapse:not(.show) {
        display: initial !important;
    }

    .collapse {
        display: initial !important;
    }

    .card-img-top {
        height: 100%;
        width: 20rem;
    }

    .custom-width-container {
        width: 50%;
        margin: auto;
    }
}
.carousel {
    height: 80vh;
    position: relative;
    top: -60px;

    .carousel-item {
        .logo {
            mask: none;

            width: 200px;
            height: auto;
            max-height: 150px;
            margin-bottom: 10px;
            object-fit: contain; // Ensures the logo fits within the specified height and width
        }

        img {
            width: 100%;
            mask: linear-gradient(to bottom, #0b0c0f 30%, rgba(0, 0, 0, 0) 80%),
                linear-gradient(to bottom, #0b0c0f 50%, rgba(0, 0, 0, 0) 80%);
            mask-size: 100% 100%;
            mask-position: bottom;
            mask-repeat: no-repeat;
            mask-composite: intersect;
        }
    }

    .image {
        background: center / cover no-repeat;
        mask: linear-gradient(to bottom, #0b0c0f 30%, rgba(0, 0, 0, 0) 80%),
            linear-gradient(to bottom, #0b0c0f 50%, rgba(0, 0, 0, 0) 80%);
        mask-size: 100% 100%;
        mask-position: bottom;
        mask-repeat: no-repeat;
        mask-composite: intersect;
        width: 100vw;
        height: 80vh;
        overflow: hidden;
    }

    .custom-button {
        display: block;
        width: fit-content;
        margin-top: 1rem;

        @include for-desktop {
            margin: 1rem 0;
        }

        .material-symbols-outlined {
            vertical-align: bottom;
            font-size: 20px;
            margin-bottom: 0px;
        }
    }

    .tagline {
        display: block;
        margin-bottom: 5px;
        color: $bright-green;
    }

    p {
        font-weight: 200;
        margin-bottom: 3px;
        font-size: 12px;
        color: #BFBFCB;
        width: 75%;

        .read-more,
        .read-less {
            width: 100%;
        }

        .read-more {
            -webkit-line-clamp: 3;
            line-clamp: 3;
        }

        @include for-phone {
            width: 100%;
        }

        span {
            color: $pure-white;

            a {
                color: $pure-white;

                &:hover {
                    color: $bright-green;
                }
            }
        }
    }

    .info {
        font-weight: 200;
        margin-bottom: .5rem;

        @include for-phone {
            display: none;
        }
    }

    .material-symbols-outlined {
        vertical-align: middle;
        font-size: 20px;
        margin-bottom: 3px;
        font-weight: 700 !important;
    }

    .carousel-indicators {
        margin-top: 1rem;

        [data-bs-target] {
            background-color: rgba(245, 243, 244, 0.1);

            &.active {
                background-color: $pure-white !important;
            }
        }
    }

    .flex {
        &.intro {
            flex-direction: column;
            gap: 0;
        }

        display: flex;
        gap: 20px;
        margin: 0 15%;
        text-align: left;

        @include for-phone {
            flex-direction: column;
            margin: 0;
        }
    }

    .carousel-caption {
        h3 {
            margin: 0;
            font-weight: 300;
            margin-bottom: 5px;
        }
    }

    .additional-info {
        color: #BFBFCB;
        display: block;
        margin-top: 3px;
    }
}